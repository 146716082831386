.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #1e1e1e;
}

.Section-div {
  padding: 0 2rem;
}

section {
  margin: 2vmin;
}

button {
  display: block;
  font-size: calc(12px + 2vmin);
  font-weight: bold;
  padding: 2vmin;
  margin: 2vmin auto;
}

.flash {
  margin: 4vmin;
  padding: 2vmin;
  color: #006cba;
  border: 1px solid #006cba;
}

.error {
  margin: 2vmin;
  color: red;
}
